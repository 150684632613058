import React from "react";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as faqApi from "../api/faq";
import { useSelector } from "react-redux";

const Faq = () => {
  const translations = useSelector((state) => state.translations.translations);
  const faqTranslations = translations["system-depoimentos"];

  const columns = [
    {
      title: "Pergunta",
      field: "pergunta",
    },
  ];

  return (
    <React.Fragment>
      <Header
        title={"Faq"}
        subtitle={"Lista de Faq relacionadas"}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={faqApi.fetchItems}
            removeFunction={faqApi.removeItem}
            statusFunction={faqApi.statusItem}
            title={"Faq"}
            url={"faq"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Faq;
