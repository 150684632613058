import React from "react";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as cidadesApi from "../api/cidades";
import { useSelector } from "react-redux";

const Cidade = () => {
  const translations = useSelector((state) => state.translations.translations);
  const cidadesTranslations = translations["system-cidades"];

  const columns = [
    {
      title: "UF Cidade",
      field: "uf",
    },
    {
      title: "Nome Cidade",
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header
        title={"Cidades"}
        subtitle={"Lista de cidades relacionadas ao parceiro"}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={cidadesApi.fetchItems}
            removeFunction={cidadesApi.removeItem}
            statusFunction={cidadesApi.statusItem}
            title={"Cidades"}
            url={"cidades"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Cidade;
