import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Container, Modal, Form } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
//import { fetchContent, removeCasino } from '../api/casinos'
import * as contentApi from "../api/content";

const Contents = () => {
  const translations = useSelector(
    (state) => state.translations.translations["system-contents"]
  );
  const [show, setShow] = useState(false);
  const [newcontent, setNewcontent] = useState({});
  const [fupdate, setfUpdate] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNew = () => {
    contentApi.addItem(newcontent).then((res) => {
      if (res.status === 201) {
        //history.push('/contents');
        setfUpdate((current) => current + 1);
        handleClose();
      }
    });
  };

  const columns = [
    {
      title: "Metatags",
      field: "_id",
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: (rowData) => (
        <a
          style={{
            textAlign: "center",
            backgroundColor: "#0586D7",
            padding: 10,
            color: "#fff",
            borderRadius: 25,
            cursor: "pointer",
          }}
          href={`/metatags/${rowData._id}`}
        >
          Metatags
        </a>
      ),
    },
    {
      title: translations["edit-name"],
      field: "name",
    },
    {
      title: translations["edit-language"],
      field: "language",
      center: true,
    },
  ];

  return (
    <React.Fragment>
      <Header title={translations["title"]} subtitle={translations["subtitle"]}>
        <i className="far fa-file-alt awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={contentApi.fetchItems}
            removeFunction={contentApi.removeItem}
            statusFunction={contentApi.statusItem}
            title={translations["title"]}
            url={"contents"}
            fupdate={fupdate}
            addFunction={handleShow}
          />
        </BigCard>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{translations["modal-title"]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleNew();
            }}
          >
            <Form.Group controlId={"name"}>
              <Form.Control
                type="text"
                placeholder={translations["modal-placeholder"]}
                value={!newcontent.name ? "" : newcontent.name}
                onChange={(e) =>
                  setNewcontent({ ...newcontent, name: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleNew}>
            {translations["modal-add"]}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {translations["modal-close"]}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Contents;
