//import axios from 'axios'
import axios from "./client";
import { serialize } from "@equipedigitalfloripa/ed-cms/api";
const url =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3000/";
const full_url = url + "content/";

const options = {
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
};
const optionsUpload = {
  withCredentials: true,
};

const fetchItems = (query) => {
  let url = full_url;
  if (query) url += "?" + serialize(query);
  return axios.get(url, options);
};

const fetchItem = (id) => axios.get(full_url + id, options);

const addItem = (item) => axios.post(full_url, item, options);

const editItem = (id, item) => axios.patch(full_url + id, item, options);

const removeItem = async (id) => {
  const item = await axios.get(full_url + id, options);

  return axios.delete(full_url + item.data.name, options);
};

const statusItem = (id, status) =>
  axios.patch(full_url + id + "/status?status=" + status, {}, options);

//Faz o upload de imagem (única)
const uploadContent = (id, formData) =>
  axios.post(full_url + id + "/imagem", formData, optionsUpload);

//Pega imagem de noticia
const getFileContent = (id) => full_url + id + "/imagem";

export {
  fetchItems,
  fetchItem,
  addItem,
  editItem,
  removeItem,
  statusItem,
  getFileContent,
  uploadContent,
};
