import React from "react";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
import * as especialidadeApi from "../api/depoimentos";
import { useSelector } from "react-redux";

const EditDepoimentos = ({ edit }) => {
  const translations = useSelector((state) => state.translations.translations);
  const depoimentosTranslations = translations["system-depoimentos"];

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "img",
      type: "dragdrop",
      title: "Imagem",
      options: {
        maxFiles: 1,
      },
    },
    {
      name: "nome",
      type: "text",
      title: "Nome Cliente",
      validation: {
        required: true,
      },
    },
    {
      name: "depoimento",
      type: "tiny",
      title: "Depoimento",
      validation: {
        required: true,
      },
    },
  ];
  return (
    <EditPage
      selectedData={selectedData}
      addFunction={especialidadeApi.addItem}
      fetchFunction={especialidadeApi.fetchItem}
      editFunction={especialidadeApi.editItem}
      uploadFunction={especialidadeApi.uploadImage}
      edit={edit}
      url="depoimentos"
    />
  );
};

export default EditDepoimentos;
