import React from "react";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
import * as cidadeApi from "../api/cidades";
import { useSelector } from "react-redux";

const EditCidade = ({ edit }) => {
  const translations = useSelector((state) => state.translations.translations);
  const cidadesTranslations = translations["system-cidades"];

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "uf",
      type: "text",
      title: "UF da cidade",
      validation: {
        required: true,
      },
      tooltip: {
        text: "Estado abreviado da cidade.",
      },
    },
    {
      name: "nome",
      type: "text",
      title: "Nome cidade",
      validation: {
        required: true,
      },
    },
  ];
  return (
    <EditPage
      selectedData={selectedData}
      addFunction={cidadeApi.addItem}
      fetchFunction={cidadeApi.fetchItem}
      editFunction={cidadeApi.editItem}
      edit={edit}
      url="cidades"
    />
  );
};

export default EditCidade;
