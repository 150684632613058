import React from "react";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as especialidadesApi from "../api/especialidades";
import { useSelector } from "react-redux";

const Especialidade = () => {
  const translations = useSelector((state) => state.translations.translations);
  const especialidadesTranslations = translations["system-especialidades"];

  const columns = [
    {
      title: "Nome Especialidade",
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header
        title={"Especialidades"}
        subtitle={"Lista de especialidades relacionadas ao parceiro"}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={especialidadesApi.fetchItems}
            removeFunction={especialidadesApi.removeItem}
            statusFunction={especialidadesApi.statusItem}
            title={"Especialidades"}
            url={"especialidades"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Especialidade;
