import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { MainLayout } from "@equipedigitalfloripa/ed-cms/layouts";

import SupportPage from "../pages/Support";
import SupportStatus from "../pages/SupportStatus";

import IndexPage from "../pages/Index";
import NotFoundPage from "../pages/NotFound";

import ContentsPage from "../pages/Contents";
import EditContentPage from "../pages/EditContent";

import NoticiasPage from "../pages/Noticias";
import EditNoticiasPage from "../pages/EditNoticia";

import CategoriasPage from "../pages/Categorias";
import EditCategoriasPage from "../pages/EditCategorias";

import ParceirosPage from "../pages/Parceiros";
import EditParceirosPage from "../pages/EditParceiros";

import EspecialidadesPage from "../pages/Especialidades";
import EditEspecialidadesPage from "../pages/EditEspecialidades";

import CidadesPage from "../pages/Cidades";
import EditCidadesPage from "../pages/EditCidades";

import ServicosPage from "../pages/Servicos";
import EditServicosPage from "../pages/EditServicos";

import DepoimentosPage from "../pages/Depoimentos";
import EditDepoimentosPage from "../pages/EditDepoimentos";

import FaqPage from "../pages/Faq";
import EditFaqPage from "../pages/EditFaq";

import {
  UsersPage,
  EditUserPage,
  EditMetatagsPage,
  EditConfigPage,
  EditSocialMidiaPage,
  TranslationsPage,
  EditTranslationsPage,
  Contatos,
  ViewContatosPage,
} from "@equipedigitalfloripa/ed-cms/pages";

import {
  startSetLang,
  startFetchTranslations,
} from "@equipedigitalfloripa/ed-cms/actions";

import { useSelector } from "react-redux";

import "@equipedigitalfloripa/ed-cms/layouts/main.css";
import "@equipedigitalfloripa/ed-cms/pages/main.css";
import "@equipedigitalfloripa/ed-cms/components/main.css";
import EditMetatags from "../pages/EditMetatags";

const SystemRouter = () => {
  const dispatch = useDispatch();
  const url =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      : "http://localhost:3000/";
  const full_url = url + "metatags/";
  const full_url_config = url + "config/";
  const full_url_socialmidia = url + "socialmidia/";
  const { loggedIn, user } = useSelector((state) => state.auth);
  const translations = useSelector(
    (state) => state.translations.translations.sidebar
  );

  useEffect(() => {
    if (user && user.preferences) {
      dispatch(startSetLang(user.preferences.lang));
      dispatch(startFetchTranslations(process.env.REACT_APP_API_URL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let navigation = [
    {
      title: translations.site_news,
      link: [
        {
          link: "/noticias",
          title: translations.site_news,
        },
        {
          link: "/categorias",
          title: translations.site_categories,
        },
      ],
    },
    {
      title: "Parceiros",
      link: [
        {
          link: "/parceiros",
          title: "Parceiros",
        },
        {
          link: "/especialidades",
          title: "Especialidades",
        },
      ],
    },
    {
      link: "/servicos",
      title: "Serviços",
    },
    {
      link: "/depoimentos",
      title: "Depoimentos",
    },
    {
      link: "/faq",
      title: "Faq",
    },
    {
      link: "/contents",
      title: translations.site_simple_content,
    },
    {
      link: "/cidades",
      title: "Cidades",
    },
  ];

  if(user && user._id == "6410af35426979fd55d9d430"){
    navigation = [
      {
        title: "Parceiros",
        link: [
          {
            link: "/parceiros",
            title: "Parceiros",
          },
          {
            link: "/especialidades",
            title: "Especialidades",
          },
        ],
      },
    ];
  }



  return loggedIn ? (
    <Router>
      <MainLayout navigation={navigation}>
        <Switch>
          <Route path="/" component={IndexPage} exact />

          <Route
            path="/edit/contents/:id"
            render={(props) => <EditContentPage {...props} edit={true} />}
          />
          <Route path="/add/contents" component={EditContentPage} />
          <Route path="/contents" component={ContentsPage} />

          <Route
            path="/edit/noticias/:id"
            render={(props) => <EditNoticiasPage {...props} edit={true} />}
          />
          <Route path="/add/noticias" component={EditNoticiasPage} />
          <Route path="/noticias" component={NoticiasPage} />

          <Route
            path="/edit/categorias/:id"
            render={(props) => <EditCategoriasPage {...props} edit={true} />}
          />
          <Route path="/add/categorias" component={EditCategoriasPage} />
          <Route path="/categorias" component={CategoriasPage} />

          <Route
            path="/edit/parceiros/:id"
            render={(props) => <EditParceirosPage {...props} edit={true} />}
          />
          <Route path="/add/parceiros" component={EditParceirosPage} />
          <Route path="/parceiros" component={ParceirosPage} />

          <Route
            path="/edit/servicos/:id"
            render={(props) => <EditServicosPage {...props} edit={true} />}
          />
          <Route path="/add/servicos" component={EditServicosPage} />
          <Route path="/servicos" component={ServicosPage} />

          <Route
            path="/edit/depoimentos/:id"
            render={(props) => <EditDepoimentosPage {...props} edit={true} />}
          />
          <Route path="/add/depoimentos" component={EditDepoimentosPage} />
          <Route path="/depoimentos" component={DepoimentosPage} />

          <Route
            path="/edit/faq/:id"
            render={(props) => <EditFaqPage {...props} edit={true} />}
          />
          <Route path="/add/faq" component={EditFaqPage} />
          <Route path="/faq" component={FaqPage} />

          <Route
            path="/edit/especialidades/:id"
            render={(props) => (
              <EditEspecialidadesPage {...props} edit={true} />
            )}
          />
          <Route
            path="/add/especialidades"
            component={EditEspecialidadesPage}
          />
          <Route path="/especialidades" component={EspecialidadesPage} />

          <Route
            path="/edit/cidades/:id"
            render={(props) => <EditCidadesPage {...props} edit={true} />}
          />
          <Route path="/add/cidades" component={EditCidadesPage} />
          <Route path="/cidades" component={CidadesPage} />

          <Route
            path="/metatags/:id"
            render={(props) => <EditMetatags {...props} edit={true} />}
          ></Route>

          <Route path="/edit/metatags">
            <EditMetatagsPage full_url={full_url} />
          </Route>
          <Route path="/edit/config">
            <EditConfigPage full_url={full_url_config} />
          </Route>
          <Route path="/edit/socialmidia">
            <EditSocialMidiaPage full_url={full_url_socialmidia} />
          </Route>

          <Route path="/edit/users/:id">
            <EditUserPage api_url={url} edit={true} />
          </Route>
          <Route path="/add/users">
            <EditUserPage api_url={url} />
          </Route>
          <Route path="/users">
            <UsersPage api_url={url} />
          </Route>

          <Route path="/edit/contatos/:id">
            <ViewContatosPage api_url={url} edit={true} />
          </Route>
          <Route path="/contatos">
            <Contatos api_url={url} />
          </Route>

          <Route path="/edit/translations/:id">
            <EditTranslationsPage api_url={url} />
          </Route>
          <Route path="/translations">
            <TranslationsPage api_url={url} />
          </Route>
          <Route path="/support">
            <SupportPage api_url={url} />
          </Route>
          <Route path="/support_status">
            <SupportStatus api_url={url} />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </MainLayout>
    </Router>
  ) : (
    <Redirect to="/login" />
  );
};

export default SystemRouter;
