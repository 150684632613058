import React from "react";
import { useSelector } from "react-redux";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
//import { fetchCasino, editCasino, uploadCasino } from '../api/casinos'
import * as contentsApi from "../api/content";

const EditContent = ({ edit }) => {
  const translations = useSelector(
    (state) => state.translations.translations["system-contents"]
  );
  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "subtitle",
      type: "text",
      title: "Subtítulo da página",
    },
    {
      name: "description",
      type: "text",
      title: "Pequena descrição",
    },
    {
      name: "content",
      type: "tiny",
      title: translations["edit-content"],
    },
  ];
  return (
    <EditPage
      title={translations["editpage-title"]}
      subtitle={translations["editpage-subtitle"]}
      selectedData={selectedData}
      addFunction={contentsApi.addItem}
      fetchFunction={contentsApi.fetchItem}
      editFunction={contentsApi.editItem}
      edit={edit}
      url="contents"
    />
  );
};

export default EditContent;
