import React, { useEffect, useState } from "react";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
import * as parceirosApi from "../api/parceiros";
import { fetchItems } from "../api/especialidades";
import { fetchItems as fetchCidades } from "../api/cidades";

const EditParceiro = ({ edit }) => {
  const [especialidades, setEspecialidades] = useState([]);
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    fetchItems({ limit: 100 }).then((res) => {
      let especialidadesOptions = [];
      res.data.forEach((especialidade) => {
        especialidadesOptions.push({
          value: especialidade._id,
          label: especialidade.nome,
        });
      });
      setEspecialidades(especialidadesOptions);
    });
  }, []);

  useEffect(() => {
    fetchCidades({ limit: 100 }).then((res) => {
      let cidadesOptions = [];
      res.data.forEach((cidade) => {
        cidadesOptions.push({
          value: cidade._id,
          label: cidade.nome,
        });
      });
      setCidades(cidadesOptions);
    });
  }, []);

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "empresa",
      type: "text",
      title: "Empresa parceira",
      validation: {
        required: true,
      },
      options: {
        maxLength: "150",
      },
      tooltip: {
        text: "Nome da empresa que está relacionada com a HELP",
      },
    },
    {
      name: "email",
      type: "text",
      title: "Email",
      options: {
        optional: true,
      },
    },
    {
      name: "especialidade",
      type: "select",
      title: "Especialidade",
      selectOptions: especialidades,
      options: {
        populated: true,
      },
      validation: {
        required: true,
      },
    },
    {
      name: "cidade",
      type: "select",
      title: "Cidade",
      selectOptions: cidades,
      options: {
        populated: true,
      },
      validation: {
        required: true,
      },
    },
    {
      name: "endereco",
      type: "text",
      title: "Endereço da empresa",
      validation: {
        required: true,
      },
      tooltip: {
        text: "Rua HELP, nº 319, CEP 88000-000",
      },
    },
    {
      name: "link",
      type: "text",
      title: "Site",
      tooltip: {
        text: "Link retirado do site da empresa",
      },
    },
    {
      name: "telefone",
      type: "text",
      title: "Telefone",
      validation: {
        required: true,
      },
    },
    {
      name: "telefone_2",
      type: "text",
      title: "Telefone secundário",
      options: {
        optional: true,
      },
    },
    {
      name: "email",
      type: "email",
      title: "Email",
    },
    {
      name: "desconto",
      type: "text",
      title: "Valor Desconto",
      options: {
        optional: true,
      },
    },
    // {
    //   name: "particular",
    //   type: "text",
    //   title: "Valor Particular",
    //   options: {
    //     optional: true,
    //   },
    // },
    // {
    //   name: "convenio",
    //   type: "text",
    //   title: "Valor Convênio",
    //   options: {
    //     optional: true,
    //   },
    // },
    {
      name: "img",
      type: "dragdrop",
      title: "Logo do convênio",
      options: {
        maxFiles: 1,
      },
      validation: {
        required: true,
      },
    },
    // {
    //   name: "obs",
    //   type: "tiny",
    //   title: "Obcervações",
    //   options: {
    //     optional: true,
    //   },
    // },
  ];
  return (
    <EditPage
      title={edit ? "Editar Parceiro" : "Adicionar Parceiro"}
      subtitle={
        edit
          ? "Aqui você edita as informações do seu parceiro"
          : "Aqui você adiciona as informações do seu parceiro"
      }
      selectedData={selectedData}
      addFunction={parceirosApi.addItem}
      fetchFunction={parceirosApi.fetchItem}
      editFunction={parceirosApi.editItem}
      uploadFunction={parceirosApi.uploadParceiro}
      edit={edit}
      url="parceiros"
    />
  );
};

export default EditParceiro;
