import React from "react";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as servicosApi from "../api/servicos";
import { useSelector } from "react-redux";

const Servicos = () => {
  const translations = useSelector((state) => state.translations.translations);
  const servicosTranslations = translations["system-servicos"];

  const columns = [
    {
      title: "Serviço",
      field: "title",
    },
    {
      title: "Slug",
      field: "slug",
    },
  ];

  return (
    <React.Fragment>
      <Header title={"Serviços"} subtitle={"Lista de serviçoss"}>
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={servicosApi.fetchItems}
            removeFunction={servicosApi.removeItem}
            statusFunction={servicosApi.statusItem}
            title={"Serviços"}
            url={"servicos"}
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Servicos;
