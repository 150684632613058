import React from "react";
// import { SupportPage } from "../components/SupportPage";
import { SupportPage } from "@equipedigitalfloripa/ed-cms/components";

const Support = () => {
  return (
    <SupportPage
      empresa={"HELP"}
      email="suporte@help.com"
      password="equipeqwas"
    />
  );
};

export default Support;
