import React from "react";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as depoimentosApi from "../api/depoimentos";
import { useSelector } from "react-redux";

const Depoimentos = () => {
  const translations = useSelector((state) => state.translations.translations);
  const depoimentosTranslations = translations["system-depoimentos"];

  const columns = [
    {
      title: "Imagem",
      field: "_id",
      filtering: false,
      sorting: false,
      cellStyle: {
        maxWidth: 66,
      },
      render: (rowData) => (
        <img
          className="noticia-img"
          src={depoimentosApi.getImgDepoimento(rowData._id)}
          alt=""
        />
      ),
    },
    {
      title: "Nome Cliente",
      field: "nome",
    },
  ];

  return (
    <React.Fragment>
      <Header
        title={"Depoimentos"}
        subtitle={"Lista de depoimentos relacionadas ao parceiro"}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            columns={columns}
            fetchFunction={depoimentosApi.fetchItems}
            removeFunction={depoimentosApi.removeItem}
            statusFunction={depoimentosApi.statusItem}
            title={"Depoimentos"}
            url={"depoimentos"}
            enableSearch
            enableDatas
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Depoimentos;
