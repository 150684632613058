import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { BigCard, Tabela, Header } from "@equipedigitalfloripa/ed-cms/components";
import * as parceirosApi from "../api/parceiros";
import { fetchItems } from "../api/especialidades";
import { fetchItems as fetchCidades } from "../api/cidades";
import { useSelector } from "react-redux";

const Parceiros = () => {
  const translations = useSelector((state) => state.translations.translations);
  const noticiasTranslations = translations["system-noticias"];
  const [especialidades, setEspecialidades] = useState([]);
  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    fetchItems({ order: { nome: 1 } }).then((res) => {
      let especialidadesOptions = {};
      res.data.forEach((especialidade) => {
        especialidadesOptions[especialidade._id] = especialidade.nome;
      });
      setEspecialidades(especialidadesOptions);
    });

    fetchCidades().then((res) => {
      let cidadesOptions = {};
      res.data.forEach((cidade) => {
        cidadesOptions[cidade._id] = cidade.nome;
      });
      setCidades(cidadesOptions);
    });
  }, []);

  const columns = [
    {
      title: "Empresa",
      field: "empresa",
      sorting: false,
      filtering: true,

      // render: (rowData) => {
      //   return (
      //     <div
      //       style={{
      //         fontSize: "14px",
      //         fontWeight: "700",
      //         margin: "5px 10px",
      //       }}
      //     >
      //       <span>
      //         Nome: <b style={{ fontSize: "12px" }}>{rowData.empresa}</b>
      //       </span>
      //       <br />
      //       <span>
      //         Email: <b style={{ fontSize: "12px" }}>{rowData.email}</b>
      //       </span>
      //       <br />
      //       <span>
      //         Telefone: <b style={{ fontSize: "12px" }}>{rowData.telefone}</b>
      //       </span>
      //       <br />
      //       <span>
      //         Endereço: <b style={{ fontSize: "12px" }}>{rowData.endereco}</b>
      //       </span>
      //       <br />
      //     </div>
      //   );
      // }, // Nome/email/contato/endereco
    },
    {
      title: "Especialidade",
      field: "especialidade",
      filtering: true,
      center: true,
      sorting: especialidades,
      render: (rowData) => {
        for (const especialidade in especialidades) {
          if (especialidade == rowData.especialidade) {
            return <>{especialidades[especialidade]}</>;
          }
        }
      },
    },
    {
      title: "Cidade",
      field: "cidade",
      center: true,
      sorting: cidades,
      render: (rowData) => {
        for (const cidade in cidades) {
          if (cidade == rowData.cidade) {
            return <>{cidades[cidade]}</>;
          }
        }
      },
    },
  ];

  // const options = {
  //   filtering: true,
  //   actionsColumnIndex: -1,
  //   paging: false
  // }

  return (
    <React.Fragment>
      <Header
        title={"Parceiros"}
        subtitle={"Listagem de todos os parceiros envolvidos com a HELP!"}
      >
        <i className="fas fa-coins awesome-header"></i>
      </Header>
      <Container className="page-content">
        <BigCard>
          <Tabela
            url={"parceiros"}
            columns={columns}
            fetchFunction={parceirosApi.fetchItems}
            removeFunction={parceirosApi.removeItem}
            statusFunction={parceirosApi.statusItem}
            enableSearch
          />
        </BigCard>
      </Container>
    </React.Fragment>
  );
};

export default Parceiros;
