import React, { useEffect, useState } from "react";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
// import { EditPage } from '../components/EditPage'
import * as servicosApi from "../api/servicos";
import { fetchItems } from "../api/categorias";
import { useSelector } from "react-redux";

const EditServicos = ({ edit }) => {
  const translations = useSelector((state) => state.translations.translations);
  const noticiasTranslations = translations["system-noticias"];

  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    fetchItems().then((res) => {
      let categoriasOptions = [];
      res.data.forEach((categoria) => {
        categoriasOptions.push({ value: categoria._id, label: categoria.nome });
      });
      setCategorias(categoriasOptions);
    });
  }, []);

  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "title",
      type: "text",
      title: "Nome serviço",
      validation: {
        required: true,
      },
      options: {
        maxLength: "150",
      },
    },
    {
      name: "subtitle",
      type: "text",
      title: "descrição simples do serviço",
    },
    {
      name: "description",
      type: "tiny",
      title: "Informações sobre o serviço",
    },
    {
      name: "imagem",
      type: "dragdrop",
      title: "Imagem demonstrativa",
      options: {
        maxFiles: 1,
      },
      validation: {
        required: true,
      },
    },
    {
      name: "slug",
      type: "text",
      title: "Url amigável",
      subtitle: "como ficará descrito no link",
      tooltip: {
        text: "Não utilize espaços apenas - para simular ex: (conheca-a-help)",
      },
    },
    {
      name: "meta_title",
      type: "text",
      title: "Metatag title",
    },
    {
      name: "meta_description",
      type: "text",
      title: "Metatag description",
    },
    {
      name: "meta_keywords",
      type: "text",
      title: "Metatag keywords",
    },
  ];
  return (
    <EditPage
      title={edit ? "Editar serviço" : "Adicionar serviço"}
      subtitle={"Informações necessárias para a edição do serviço"}
      selectedData={selectedData}
      addFunction={servicosApi.addItem}
      fetchFunction={servicosApi.fetchItem}
      editFunction={servicosApi.editItem}
      uploadFunction={servicosApi.uploadServicos}
      edit={edit}
      url="servicos"
    />
  );
};

export default EditServicos;
