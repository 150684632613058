import React from "react";
import { EditPage } from "@equipedigitalfloripa/ed-cms/components";
import * as especialidadeApi from "../api/metatags";

const EditMetatags = ({ edit }) => {
  const selectedData = [
    {
      name: "_id",
      type: "hidden",
      title: "Id",
    },
    {
      name: "title",
      type: "text",
      title: "Title",
      validation: {
        required: true,
      },
    },
    {
      name: "description",
      type: "text",
      title: "Description",
      validation: {
        required: true,
      },
    },
    {
      name: "keywords",
      type: "text",
      title: "Keywords",
      validation: {
        required: true,
      },
    },
    {
      name: "og_image",
      type: "text",
      title: "og:image url",
      validation: {
        required: true,
      },
    },
  ];
  return (
    <EditPage
      selectedData={selectedData}
      addFunction={especialidadeApi.addItem}
      fetchFunction={especialidadeApi.fetchItemContent}
      editFunction={especialidadeApi.editItemContent}
      edit={edit}
      url="contents"
    />
  );
};

export default EditMetatags;
