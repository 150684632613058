import React from "react";
// import { SupportStatus } from "../components/SupportPage";
import { SupportStatus } from "@equipedigitalfloripa/ed-cms/components";

const Support = () => {
  return (
    <SupportStatus
      empresa={"HELP"}
      email={"suporte@help.com"}
      password={"equipeqwas"}
    />
  );
};

export default Support;
